import React from "react"

import AboutBio from "../components/about-bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="About" keywords={[`about`]} />
    <AboutBio />
  </Layout>
)

export default IndexPage
