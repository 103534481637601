import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Container = styled.div`
  text-align: center;
`

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 78vh;

`

const Description = styled.p`
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`


const NameHeader = styled.h1`
  font-size: 2.0rem;
  margin-bottom: 5;
`

const AboutBio = () => (
      <OuterContainer>
        <Container>
          <NameHeader>About</NameHeader>
          <Description>
            Vicente Rocha here! I'm a Software Engineer. I mostly do backend, specially with Python. Currently very actively getting my way into web3!
            <br/>
            <br/>
            This is my personal website, which started as a way to showcase the strategies I use to improve my daily workflows, but as begining to also include my journey into web3.
            <br/>
            <br/>
            The name <i><b>Did I Improve This</b></i> came from question I always ask myself after implementing something that is suposed to improve my workflow: Did I improve this or not?
            Hopefully the answer is almost always yes!
            <br/>
            <br/>
            I hope this journey helps others along the way.
            <br/>
            <br/>
            Vicente
          </Description>
        </Container>
      </OuterContainer>
)

NameHeader.propTypes = {
  siteTitle: PropTypes.string,
  subtitle: PropTypes.string,
}

NameHeader.defaultProps = {
  siteTitle: ``,
  subtitle: ``,
}

export default AboutBio
